import "./App.scss";

import { useInView } from "react-intersection-observer";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";

import Section from "./components/Section/Section";
import Mustache from "./pages/Mustache/Mustache";
import Participants from "./pages/Participants/Participants";
import PreviousYears from "./pages/PreviousYears/PreviousYears";

import smoothscroll from "smoothscroll-polyfill";

let scrollToElementTimeoutId;
// kick off the polyfill!
smoothscroll.polyfill();

const scrollToElement = (element) => {
  clearTimeout(scrollToElementTimeoutId);
  setTimeout(
    (scrollToElementTimeoutId = () =>
      element.scrollIntoView({ behavior: "smooth", block: "start" })),
    100
  );
};

const scrollNavigateUp = () => {
  const element = window.document.getElementsByClassName(
    "mustache-main visible"
  );
  const destination = element?.length > 0 ? window.document.getElementById("last-section") : window.document.getElementById("participants");
  scrollToElement(destination);
};

const scrollNavigateDown = () => {
  const element = window.document.getElementsByClassName(
    "previous-years-wrapper visible"
  );
  const destination = element?.length > 0 ? window.document.getElementById("main-content") : window.document.getElementById("participants");
  scrollToElement(destination);
};

const App = () => {
  const [animationMoustacheRef, sectionMoustacheIsVisible] = useInView({
    threshold: 0.2,
  });
  const [animationPreviousPagesRef, sectionPreviousPagesIsVisible] = useInView({
    threshold: 0.2,
  });

  const mustacheMainClasses = `mustache-main ${
    sectionMoustacheIsVisible ? "visible" : ""
  }`;

  const previousPagesClasses = `previous-years-wrapper  ${
    sectionPreviousPagesIsVisible ? "visible" : ""
  }`;

  return (
    <ReactScrollWheelHandler
      upHandler={(e) => scrollNavigateUp()}
      downHandler={(e) => scrollNavigateDown()}
    >
      <div className="app-wrapper container">
        <div
          ref={animationMoustacheRef}
          className={mustacheMainClasses}
          id="main-content"
        >
          <Section render={<Mustache />} />
        </div>

        <div id="participants">
          <Section render={<Participants />} />
        </div>

        <div
          ref={animationPreviousPagesRef}
          className={previousPagesClasses}
          id="last-section"
        >
          <Section render={<PreviousYears />} />
        </div>
      </div>
    </ReactScrollWheelHandler>
  );
};

export default App;
