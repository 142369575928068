import "./Mustache.scss";

import classNames from "classnames";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import gsap from "gsap";

import Loading from "../../components/Loading/Loading";
import SceneInit from "../../lib/SceneInit";
import Modal from "../../components/Modal/Modal";
import arrow from "../../assets/images/arrow.png";

const canvasId = "threejs-canvas";
let sceneObj;
let animationStarted = false;

const Mustache = (props) => {
  const history = useHistory();
  const [percentageLoaded, setPercentageLoaded] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const classesMain = classNames("main-text");
  const classesButton = classNames("enter-button");

  const totalBytesToLoad = 47098696; //xhr.total not working. It is always returnin 0;

  useEffect(() => {
    sceneObj = new SceneInit(canvasId);
  }, []);

  useEffect(() => {
    if (history.location.pathname !== "/") return;
    sceneObj.initialize();

    const glftLoader = new GLTFLoader();

    //Loading the model
    glftLoader.load(
      "./assets/models/mustache.glb",
      (gltfScene) => {
        gltfScene.scene.scale.set(0.3, 0.3, 0.3);
        //This animation is to get the model/moustache object close to us and move back to its position
        gsap.to(sceneObj.camera.position, {
          z: 1,
          duration: 1,
          ease: "back.out(1.4)",
        });
        //To spin the moustache object in y-axis
        gsap.to(gltfScene.scene.rotation, {
          y: 3,
          duration: 4,
          delay: 1,
        });

        //Animation for the title
        let tl = gsap.timeline();

        tl.from(
          ".main-text h1",
          {
            duration: 0.75,
            y: 150,
            autoAlpha: 0,
            ease: "Power3.out",
            stagger: 1.5,
          },
          "+=0.09"
        );

        sceneObj.scene.add(gltfScene.scene);
      },
      (xhr) => {
        setPercentageLoaded(xhr.loaded / totalBytesToLoad);
      }
    );
  }, [history.location.pathname]);

  useEffect(() => {
    if (percentageLoaded > 0.99 && !animationStarted) {
      animationStarted = true;
      sceneObj.animate();
    }
  }, [history, percentageLoaded]);

  let mainText = percentageLoaded >= 0.99 && (
    <div className={classesMain}>
      <div className="participate-link" onClick={() => setShowModal(true)}>
        I want to participate
        <img src={arrow} alt="arrow" className="arrow" />
      </div>
      <h1 className="info1">
        <span
          style={{
            transform: "rotate(5.29deg)",
            fontFamily: `"poppins", serif`,
          }}
        >
          G
        </span>
        <span
          style={{
            transform: "rotate(3.9deg)",
            fontFamily: `"poppins", serif`,
          }}
        >
          R
        </span>
        <span
          style={{
            transform: "rotate(2.49deg)",
            fontFamily: `"poppins", serif`,
          }}
        >
          O
        </span>
        <span
          style={{
            transform: "rotate(0.68deg)",
            fontFamily: `"poppins", serif`,
          }}
        >
          W
        </span>
        &nbsp;
        <span
          style={{
            transform: "rotate(-1.82deg)",
            fontFamily: `"utopia-std", serif`,
          }}
        >
          A
        </span>
        &nbsp;
        <span
          style={{
            transform: "rotate(-2.87deg)",
            fontFamily: `"utopia-std", serif`,
          }}
        >
          M
        </span>
        <span
          style={{
            transform: "rotate(-3.73deg)",
            fontFamily: `"utopia-std", serif`,
          }}
        >
          O
        </span>
      </h1>
      <h1 className="info2">
        <span
          style={{
            transform: "rotate(5.58deg)",
            fontFamily: `"utopia-std", serif`,
          }}
        >
          S
        </span>
        <span
          style={{
            transform: "rotate(4.28deg)",
            fontFamily: `"utopia-std", serif`,
          }}
        >
          A
        </span>
        <span
          style={{
            transform: "rotate(2.85deg)",
            fontFamily: `"utopia-std", serif`,
          }}
        >
          V
        </span>
        <span
          style={{
            transform: "rotate(1.45deg)",
            fontFamily: `"utopia-std", serif`,
          }}
        >
          E
        </span>
        &nbsp;
        <span
          style={{
            transform: "rotate(-0.44deg)",
            fontFamily: `"utopia-std", serif`,
          }}
        >
          A
        </span>
        &nbsp;
        <span
          style={{
            transform: "rotate(-2.46deg)",
            fontFamily: `"poppins", serif`,
          }}
        >
          B
        </span>
        <span
          style={{
            transform: "rotate(-3.81deg)",
            fontFamily: `"poppins", serif`,
          }}
        >
          R
        </span>
        <span
          style={{
            transform: "rotate(-5.28deg)",
            fontFamily: `"poppins", serif`,
          }}
        >
          O
        </span>
      </h1>
    </div>
  );

  return (
    <div className="mustache-wrapper" id="section1">
      <Loading percent={percentageLoaded}></Loading>

      <canvas id="threejs-canvas"></canvas>
      {mainText}

      <div className="btn-wrapper">
        <button
          onClick={(e) => {
            if (sceneObj.wasButtonClicked()) return;
            let participants = document.getElementById("participants");

            sceneObj.btnClicked();

            setTimeout(() => {
              participants &&
                participants.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
            }, 1600);
          }}
          className={classesButton}
        >
          Moustacheverse
        </button>
      </div>
      <Modal show={showModal} handleClose={() => setShowModal(false)}>
        <div className="participate">How do I participate?</div>
        <div className="join-team">Sign up on the official Movember site</div>
        <a
          className="website-url cursor"
          href="https://nl.movember.com/"
          style={{ display: "inline-block" }}
        >
          www.movember.com
          <img
            src={arrow}
            alt="arrow"
            className="arrow"
            style={{ position: "relative", top: "3px" }}
          />
        </a>
        <div className="join-team">Join our team</div>
        <div className="website-url">
          Click on your profile and select team. Pick “join an existing team”
          and use the following team name: MOBMOVEMBER.{" "}
        </div>
        <div className="join-team">Share your profile</div>
        <div className="website-url" style={{marginBottom: "10px"}}>
          Share your profile with people and grow a mo
        </div>
      </Modal>
    </div>
  );
};
export default Mustache;
