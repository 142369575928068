import "./Person.scss";

import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const Person = (props) => {
  const person = props.data;

  const { ref: animationRef, inView: sectionIsVisible } = useInView();
  console.log(sectionIsVisible);

  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.7
  };
  
  useEffect(() => {
    const observerCallback = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // fade in observed elements that are in view
          entry.target.classList.replace('inactive', 'fadeIn');
        } else {
          // fade out observed elements that are not in view
          entry.target.classList.replace('fadeIn', 'inactive');
        }
      });
    }
    
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    
    const fadeElms = document.querySelectorAll('.flex');
    fadeElms.forEach(el => observer.observe(el));
  })

  return (
    <div
      ref={animationRef}
      className={`person-wrapper-animation person-wrapper ${
        props.refVal ? "active" : "inactive"
      }`}
      id={"person-" + (props.indexValue + 1)}
      onClick={() => window.open(person.link && person.link, "_self")}
    >
      <div className="flex inactive">
        <img src={person.image} alt="person-avatar" className="personImage" />
        <div className="name">
          {person.name}
          <span>
            <a href={person.link && person.link}>→</a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Person;
