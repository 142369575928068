import "./Gallery.scss";

import { useInView } from "react-intersection-observer";

import Person from "./Person/Person";
import { useRef } from "react";
import upArrow from "../../assets/images/arrow_up.png";
import downArrow from "../../assets/images/arrow_down.png";

const Gallery = (props) => {
  const { ref: animationRef, inView: sectionIsVisible } = useInView();
  const scrollRef = useRef(null);

  const handleScrollButton = (direction) => {
    const { current } = scrollRef;
    
    if (current) {
      const scrollAmount = direction === 'down' ? 200 : -200;
      current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
    }
  }

  return (
    <div
      ref={animationRef}
      className={`gallery-wrapper  ${sectionIsVisible ? "animate" : ""}`}
    >
      <div
        ref={scrollRef}
        className={`carrousel ${sectionIsVisible ? "animate" : ""}`}
      >
        {props.people.map((person, index) => (
          <Person
            refProp={scrollRef}
            data={person}
            key={index}
            indexValue={index}
            refVal={sectionIsVisible}
          />
        ))}
      </div>
      <div className="scroll-container">
        <img src={upArrow} alt="up-arrow" className="scroll-arrows" onClick={() => handleScrollButton('up')}/>
        <div></div>
        <img src={downArrow} alt="down-arrow" className="scroll-arrows" onClick={() => handleScrollButton('down')}/>
      </div>
    </div>
  );
};

export default Gallery;
