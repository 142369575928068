import './Section.scss';

const Section = (props) => {
  return (
    <div className="section">
      <div className="ratio"></div>
      {props.render}
    </div>
  );
};
export default Section;
