import axios from "axios";

/**
 * API request to fetch the participants
 * return response of the api request
 */
const getParticipants = () => {
  const url = "./json/participants.json";
  return axios.get(url);
};

export { getParticipants };
