import { detect } from "detect-browser";
import gsap from "gsap";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Stats from "three/examples/jsm/libs/stats.module";

export default class SceneInit {
  constructor(canvasId) {
    // NOTE: Core components to initialize Three.js app.
    this.scene = undefined;
    this.camera = undefined;
    this.renderer = undefined;

    // NOTE: Camera params;
    this.fov = 75;
    this.nearPlane = 1;
    this.farPlane = 1000;
    this.canvasId = canvasId;

    // NOTE: Additional components.
    this.clock = undefined;
    this.stats = undefined;
    this.controls = undefined;

    // NOTE: Lighting is basically required.
    this.ambientLight = undefined;
    this.directionalLight = undefined;

    this.buttonHasBeenClicked = false;
    this.animationFrameId = undefined;

    this.browser = detect();
    this.browserVelocityFactor = this.browser.name === "safari" ? 2.1 : 1;
    this.velocity = 1 * this.browserVelocityFactor;
  }

  initialize() {
    //Reduced the size of moustache to target the viewport at max 767px wide
    let mm = gsap.matchMedia();
    mm.add("(max-width: 767px)", () => {
      this.fov = 90;
    });

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      window.innerWidth / window.innerHeight,
      0.01,
      10
    );
    this.camera.position.z = 5;
    this.camera.rotation.z = 1;
    // NOTE: Specify a canvas which is already created in the HTML.
    const canvas = document.getElementById(this.canvasId);
    const app = document.getElementById("section1");

    this.renderer = new THREE.WebGLRenderer({
      canvas,
      // NOTE: Anti-aliasing smooths out the edges.
      antialias: true,
    });
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    // this.renderer.shadowMap.enabled = true;
    app.appendChild(this.renderer.domElement);

    this.clock = new THREE.Clock();
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enabled = false;
    this.stats = Stats();
    // app.appendChild(this.stats.dom);

    // ambient light which is for the whole scene
    this.ambientLight = new THREE.AmbientLight(0x736044, 1);
    this.ambientLight.castShadow = true;
    this.scene.add(this.ambientLight);

    // directional light - parallel sun rays
    this.directionalLight = new THREE.DirectionalLight(0x736044, 1);
    // this.directionalLight.castShadow = true;
    this.directionalLight.position.set(0, 32, 64);
    this.scene.add(this.directionalLight);

    // if window resizes
    window.addEventListener("resize", () => this.onWindowResize(), false);
    return this.scene;
  }

  animate() {
    if (this.ambientLight.intensity >= 9 && !this.buttonHasBeenClicked) return;

    if (this.ambientLight.intensity < 0.9) {
      this.ambientLight.intensity += 0.005 * this.velocity;
      this.directionalLight.intensity += 0.005 * this.velocity;
    }

    if (this.buttonHasBeenClicked) {
      if (this.scene.position.z < 64) {
        if (this.scene.position.z < 5) {
          this.scene.position.z += 0.03 * this.velocity;
        } else {
          this.scene.position.z += 1.5 * this.velocity;
          this.scene.position.y += 0.01 * this.velocity;
        }
      } else {
        setTimeout(() => {
          this.scene.position.y = 0;
          this.scene.position.z = 0;
        }, 1000);

        this.buttonHasBeenClicked = false;
      }
    }

    this.animationFrameId = window.requestAnimationFrame(
      this.animate.bind(this)
    );
    this.render();
    // this.stats.update();
    this.controls.update();
  }

  render() {
    // NOTE: Update uniform data on each render.
    // this.uniforms.u_time.value += this.clock.getDelta();
    this.renderer.render(this.scene, this.camera);
  }

  onWindowResize() {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
  }

  btnClicked() {
    this.buttonHasBeenClicked = true;
  }

  stopAnimation() {
    window.cancelAnimationFrame(this.animationFrameId);
  }

  wasButtonClicked() {
    return this.buttonHasBeenClicked;
  }
}
