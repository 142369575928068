import "./Modal.scss";
import close from "../../assets/images/close.svg";

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div>
          <img
            src={close}
            alt="close"
            onClick={handleClose}
            className="close"
          />
        </div>
        <div className="modal-content">{children}</div>
      </section>
    </div>
  );
};

export default Modal;
