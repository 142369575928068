import './PreviousYears.scss';

import classNames from 'classnames';
import { useInView } from 'react-intersection-observer';

const PreviousYears = (props) => {
  const { ref: animationRef, inView: sectionIsVisible } = useInView();

  const classes = classNames("content-wrapper", {
    "fade-out": props.switchingPages,
    "fade-in": !props.switchingPages,
  });

  return (
    <div
      ref={animationRef}
      className={`page-base-wrapper previous-years-wrapper ${
        sectionIsVisible ? "visible" : ""
      }`}
      id="previous-years"
    >
      <div className={classes}>
        <div className="general-content">
          <h1 className="title">PREVIOUS YEARS</h1>

          <div className="years-wrapper">
            <div className="year">
              2021
              <div className="badge">
                <div className="title">
                  WE RAISED <br />{" "}
                  <span className="description">€ 2689,00</span>
                </div>
              </div>
            </div>

            <div className="year">
              2019
              <div className="badge yellow">
                <div className="title">
                  WE RAISED <br />{" "}
                  <span className="description">€ 1126,00</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PreviousYears;
