import "./Participants.scss";

import classNames from "classnames";
import { useEffect, useState } from "react";

import Gallery from "../../components/Gallery/Gallery";
import { getParticipants } from "../../services/participants.service";

const Participants = (props) => {
  const [pageControl, setPageControl] = useState({
    page: 1,
    totalPages: 2,
    data: [],
  });

  const classes = classNames("content-wrapper", {
    "fade-out": props.switchingPages,
    "fade-in": !props.switchingPages,
  });

  //Get the participants list
  const getParticipantsList = () => {
    const people = [];
    getParticipants()
      .then((response) => {
        response?.data?.data?.forEach((user) => {
          people.push({
            name: user?.firstname,
            image: user?.heroUrl,
            link: user?.mospaceUrl,
            isCaptain: user?.isCaptain
          });
        });
        setPageControl((prev) => ({
          ...prev,
          data: people,
        }));
      })
      .catch((error) => {
        console.log(error?.message || "Please try again!");
      });
  };

  useEffect(() => {
    getParticipantsList();
  }, []);

  return (
    <div className="page-base-wrapper participants-wrapper" id="participants">
      <div className={classes}>
        <div className="general-content">
          <h1 className="title">PARTICIPANTS</h1>
          <Gallery people={pageControl.data} />
          <h2 className="year">2022</h2>
        </div>
      </div>
    </div>
  );
};
export default Participants;
