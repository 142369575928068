import "./Loading.scss";

const percentColors = [
  { pct: 0, color: { r: 0, g: 0, b: 0 } },
  { pct: 20, color: { r: 54, g: 37, b: 17 } },
  { pct: 50, color: { r: 156, g: 115, b: 77 } },
  { pct: 75, color: { r: 227, g: 187, b: 151 } },
  { pct: 100, color: { r: 0, g: 0, b: 0 } },
];

const getColorForPercentage = function (pct) {
  let i;
  for (i = 1; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  const lower = percentColors[i - 1];
  const upper = percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };

  return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
};

const Loading = (props) => {
  const percent = Math.floor(props.percent * 100);

  return (
    <div
      className="loading-wrapper"
      style={{
        backgroundColor: getColorForPercentage(percent),
        display: percent >= 100 ? "none" : "flex",
      }}
    >
      {percent}%
      <div className="mustache-loading-bar-wrapper">
        <div className="loading-percentage-wrapper">
          {percent >= 20 && percent < 50 ? (
            <img
              src="assets/fill1.svg"
              className="loading-percentage"
              alt="loading 20%"
            />
          ) : null}

          {percent >= 50 && percent < 75 ? (
            <img
              src="assets/fill2.svg"
              className="loading-percentage"
              alt="loading 50%"
            />
          ) : null}

          {percent >= 75 && percent < 95 ? (
            <img
              src="assets/fill3.svg"
              className="loading-percentage"
              alt="loading 75%"
            />
          ) : null}

          {percent >= 95 ? (
            <img
              src="assets/fill4.svg"
              className="loading-percentage"
              alt="loading 100%"
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Loading;
